import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div className="error-page">
      <div className="flex flex-col items-center justify-center w-full bg-primary h-screen">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-white">404</h1>
          <p className="text-lg pb-6 pt-4 text-white">
            {t("errorPage.notFound")}
          </p>
          <Link
            to="/"
            className="bg-secondary text-lg rounded-md text-primary font-bold hover:bg-deepSecondary transition-colors px-4 py-2"
          >
            {t("errorPage.goHome")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
