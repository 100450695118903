import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa6";
import LogoWhite from "../assets/images/logo.svg";
import LogoGold from "../assets/images/logo-gold.svg";
import EnFlag from "../assets/images/en.svg";
import AlFlag from "../assets/images/al.svg";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const isSpecialPage = /\/shitje\/[^/]+/.test(location.pathname);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [activeLanguage, setActiveLanguage] = useState(currentLanguage);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 30);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
    setDropdownOpen(false);
    setIsMenuOpen(false);
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 w-full h-20 z-50 transition-all duration-300 ${
        isScrolled ? "bg-primary shadow-lg" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto h-full flex items-center justify-between px-4 sm:px-6 lg:px-8">
        <Link to="/" className="flex items-center">
          <img
            src={isScrolled || isSpecialPage ? LogoGold : LogoWhite}
            alt="BritALB Logo"
            className="w-10 h-auto"
          />
          <h1
            className={`text-2xl font-normal ml-4 ${
              isScrolled || isSpecialPage
                ? "bg-gradient-to-r from-[#A07B1F] to-[#E3C651] overline bg-clip-text text-transparent"
                : "text-white"
            }`}
          >
            BRITALB PROPERTIES
          </h1>
        </Link>
        <div className="flex items-center">
          <button
            className="lg:hidden text-white focus:outline-none"
            aria-label="Toggle Menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <FaBars className="w-6 h-6 text-white" />
          </button>
          <nav
            className={`${
              isMenuOpen ? "block" : "hidden"
            } lg:flex absolute lg:relative top-16 lg:top-0 right-4 lg:right-0 w-3/6 md:w-2/6 lg:w-auto bg-primary lg:bg-transparent rounded-lg lg:rounded-none outline outline-1 outline-white lg:outline-none`}
          >
            <div className="flex flex-col lg:flex-row items-center font-semibold text-white lg:space-x-6">
              <Link
                to="/"
                className="hover:bg-transparent lg:hover:text-secondary transition-colors py-2 lg:py-0"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("header.home")}
              </Link>
              <Link
                to="/qera"
                className="hover:bg-transparent lg:hover:text-secondary transition-colors py-2 lg:py-0"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("header.rent")}
              </Link>
              <Link
                to="/shitje"
                className="hover:bg-transparent lg:hover:text-secondary transition-colors py-2 lg:py-0"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("header.sale")}
              </Link>
              <Link
                to="/kontakt"
                className="hover:bg-transparent lg:hover:text-secondary transition-colors py-2 lg:py-0"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("header.contact")}
              </Link>
              <Link
                to="/rreth-nesh"
                className="hover:bg-transparent lg:hover:text-secondary transition-colors py-2 lg:py-0"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("header.aboutUs")}
              </Link>
              <div className="relative w-fit my-2">
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  aria-label="Change Language"
                  className="flex flex-row items-center justify-center"
                >
                  <img
                    src={activeLanguage.startsWith("en") ? EnFlag : AlFlag}
                    alt={
                      activeLanguage.startsWith("en") ? "English" : "Albanian"
                    }
                    className="w-8 h-5 mr-2"
                  />
                  <span className="block lg:hidden mr-1 lg:mr-0">
                    {activeLanguage.startsWith("en") ? "English" : "Albanian"}
                  </span>
                  <svg
                    className={`w-4 h-4 transform ${
                      dropdownOpen ? "rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {dropdownOpen && (
                  <div className="absolute mt-3 right-0 rounded-md bg-primary shadow-lg">
                    <div
                      className="flex flex-col"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {!activeLanguage.startsWith("en") && (
                        <button
                          onClick={() => changeLanguage("en")}
                          aria-label="English"
                          className="flex items-center w-full text-white px-4 py-2"
                        >
                          <img
                            src={EnFlag}
                            alt="English"
                            className="w-8 h-5 mr-2"
                          />
                          <span className="w-24">English</span>
                        </button>
                      )}
                      {activeLanguage !== "al" && (
                        <button
                          onClick={() => changeLanguage("al")}
                          aria-label="Albanian"
                          className="flex items-center w-full text-white px-4 py-2"
                        >
                          <img
                            src={AlFlag}
                            alt="Albanian"
                            className="w-8 h-5 mr-2"
                          />
                          <span className="w-24">Albanian</span>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
