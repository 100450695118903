import { useState } from "react";
import NoImage from "../assets/images/no-image.webp";

const Gallery = ({ images = [] }) => {
  const [mainImage, setMainImage] = useState(
    images.length > 0 ? images[0].url : NoImage
  );

  const isNoImage = mainImage === NoImage;

  return (
    <div className="grid gap-2 h-[400px] sm:h-[400px] md:h-[450px] lg:h-[550px] xl:h-[600px] xl:flex">
      <div className="flex-1">
        <img
          src={mainImage}
          alt="Main"
          className={`w-full object-cover rounded-md ${
            isNoImage
              ? "h-[400px] sm:h-[400px] md:h-[450px] lg:h-[550px] xl:h-[600px]"
              : "2xl:h-full h-[300px] sm:h-[320px] md:h-[340px] lg:h-[400px] xl:h-[600px]"
          }`}
        />
      </div>
      {images.length > 1 && (
        <div className="flex flex-row xl:flex-col xl:space-y-2 xl:space-x-0 space-x-2 h-full overflow-hidden overflow-x-auto xl:overflow-y-auto custom-scrollbar">
          {images.map((image, index) => (
            <img
              key={index}
              src={image.url || NoImage}
              alt={`Thumbnail ${index}`}
              className="w-full h-[92px] sm:h-[72px] md:h-[102px] lg:h-[142px] object-cover cursor-pointer rounded-md"
              onClick={() => setMainImage(image.url)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Gallery;
