import AgentCard from "../components/AgentCard";
import { useSelector, useDispatch } from "react-redux";
import { fetchAgentData } from "../slices/agentDataSlice";
import { useEffect } from "react";
import AboutHeaderImage from "../assets/images/about-header.webp";
import AboutImage from "../assets/images/rreth-nesh.webp";
import Meta from "../components/Meta";
import { useTranslation } from "react-i18next";
import HeaderImage from "../components/HeaderImage";
import CoverImage from "../assets/images/cover.webp";

const AboutUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: agents,
    status: agentStatus,
    error: agentError,
  } = useSelector((state) => state.agentData);

  useEffect(() => {
    if (agentStatus === "idle") {
      dispatch(fetchAgentData());
    }
  }, [agentStatus, dispatch]);

  return (
    <>
      <Meta
        title={t("aboutUs.meta.title")}
        subtitle={t("aboutUs.meta.subtitle")}
        description={t("aboutUs.meta.description")}
        keyword={t("aboutUs.meta.keyword")}
        image={CoverImage}
      />
      <HeaderImage
        image={AboutHeaderImage}
        title={t("aboutUs.headerTitle")}
        actualPage={t("aboutUs.subtitle")}
      />
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 container mx-auto py-8 lg:py-12 gap-6 px-4 sm:px-6 lg:px-8">
        <div className="text-justify">
          <h2 className="text-3xl text-left font-semibold text-gray-800 mb-4">
            {t("aboutUs.companyHistoryTitle")}
          </h2>
          <div className="text-gray-800 mb-4">
            {t("aboutUs.firstParagraph")}
          </div>
          <div className="text-gray-800 mb-4">
            <h4 className="font-semibold text-xl mb-2">
              {t("aboutUs.secondHeading")}
            </h4>{" "}
            {t("aboutUs.secondParagraph")}
          </div>
          <div className="text-gray-800 mb-4">
            <h4 className="font-semibold text-xl mb-2">
              {t("aboutUs.thirdHeading")}
            </h4>{" "}
            {t("aboutUs.thirdParagraph")}
          </div>
          <div className="text-gray-800 mb-4">
            <strong>{t("aboutUs.fourthHeading")}</strong>{" "}
            {t("aboutUs.fourthParagraph")}
          </div>
          <div className="text-gray-800 mb-4">
            <strong>{t("aboutUs.fifthHeading")}</strong>{" "}
            {t("aboutUs.fifthParagraph")}
          </div>
        </div>
        <div className="w-full h-full">
          <img
            src={AboutImage}
            alt={t("aboutUs.aboutImageAlt")}
            className="w-full h-[400px] sm:h-[550px] md:h-[600px] lg:h-full xl:h-[850px] 2xl:h-[750px] object-cover sm:object-center"
          />
        </div>
      </div>

      <div className="container mx-auto pb-8 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-8 gap-6 text-gray-800 text-justify">
          <div>
            <h3 className="font-semibold text-xl mb-4">
              {t("aboutUs.colons.firstHeading")}
            </h3>
            <p>{t("aboutUs.colons.firstText")}</p>
          </div>
          <div>
            <h3 className="font-semibold text-xl mb-4">
              {t("aboutUs.colons.secondHeading")}
            </h3>
            <p>{t("aboutUs.colons.secondText")}</p>
          </div>
          <div>
            <h3 className="font-semibold text-xl mb-4">
              {t("aboutUs.colons.thirdHeading")}
            </h3>
            <p>{t("aboutUs.colons.thirdText")}</p>
          </div>
        </div>
      </div>

      <div className="container mx-auto pb-12 px-4 flex flex-col items-center">
        <h2 className="text-3xl font-semibold text-gray-800 mb-0">
          {t("aboutUs.ourTeamTitle")}
        </h2>
        <div className="w-48 border-t border-1 border-black my-5"></div>
        {agentStatus === "loading" && (
          <p className="text-lg">{t("aboutUs.loading")}</p>
        )}
        {agentStatus === "failed" && (
          <p className="text-lg">
            {t("aboutUs.error")} {agentError}
          </p>
        )}
        {agentStatus === "succeeded" && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 w-full">
            <AgentCard agents={agents.records} isAboutUsPage={true} />
          </div>
        )}
      </div>
    </>
  );
};

export default AboutUs;
