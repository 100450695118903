import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Meta from "../components/Meta";
import Square from "../components/Square";
import Impact from "../components/Impact";
import HomeVideo from "../components/HomeVideo";
import Team from "../assets/images/our-mission.webp";
import Menaxhimi from "../assets/images/menaxhimi.webp";
import FirstIcon from "../assets/images/first-icon.svg";
import SecondIcon from "../assets/images/second-icon.svg";
import ThirdIcon from "../assets/images/third-icon.svg";
import RentImage from "../assets/images/rent-image.webp";
import SaleImage from "../assets/images/sale-image.webp";
import useIntersectionObserver from "../utils/useIntersectionObserver";
import CoverImage from "../assets/images/cover.webp";

const AnimatedText = ({ children, className, animation }) => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <div
      ref={ref}
      className={`${className} ${
        isVisible ? `animate__animated ${animation}` : ""
      }`}
    >
      {children}
    </div>
  );
};

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta
        title={t("homepage.meta.title")}
        subtitle={t("homepage.meta.subtitle")}
        description={t("homepage.meta.description")}
        keyword={t("homepage.meta.keyword")}
        image={CoverImage}
      />
      <div className="flex flex-col min-h-screen">
        <HomeVideo />

        <div className="flex flex-col items-center justify-center px-4 py-4 pt-10 lg:py-12 lg:pt-16 h-full bg-white lg-bg-style xl:mt-4 xl:pb-16">
          <AnimatedText
            className="text-center mb-10 sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-4xl"
            animation="animate__fadeInUp"
          >
            <h2 className="text-sm font-bold uppercase px-6 text-black">
              {t("homepage.expertise")}
            </h2>
            <div className="w-24 border-t border-1 border-black mx-auto my-4"></div>
            <h1 className="text-3xl lg:text-4xl font-tenor text-gray-900 mt-2 xl:py-4">
              {t("homepage.teamTitle")}
            </h1>
            <p className="mt-4 mx-auto text-gray-800">
              {t("homepage.teamDescription")}
            </p>
          </AnimatedText>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="w-full">
            <img
              src={Team}
              alt="Team"
              className="w-full h-full lg:h-[600px] 2xl:h-[700px] aspect-4/3 object-cover"
            />
          </div>
          <div className="w-full bg-primary text-white flex flex-col justify-center p-4 py-8 sm:p-8 sm:py-14">
            <AnimatedText
              className="text-left 2xl:max-w-3xl 2xl:mx-auto"
              animation="animate__fadeInRight"
            >
              <h2 className="text-3xl lg:text-4xl font-tenor font-normal mb-2">
                {t("homepage.mission")}
              </h2>
              <hr className="border-t border-white w-full my-4" />
              <p className="text-base leading-relaxed text-justify">
                {t("homepage.missionDescription")}
              </p>
            </AnimatedText>

            <AnimatedText className="mt-10" animation="animate__fadeInRight">
              <h3 className="text-2xl font-normal font-tenor text-center mb-6">
                {t("homepage.valuesTitle")}
              </h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-6">
                <div className="text-center">
                  <div className="flex justify-center items-center w-24 h-24 mx-auto border-2 mb-4 border-white hover:bg-secondary hover:bg-opacity-20 transition-colors rounded-full">
                    <img
                      src={FirstIcon}
                      alt="Integrity"
                      className="w-auto h-12"
                    />
                  </div>
                  <p className="font-medium italic uppercase">
                    {t("homepage.integrity")}
                  </p>
                </div>
                <div className="text-center">
                  <div className="flex justify-center items-center w-24 h-24 mx-auto border-2 mb-4 border-white hover:bg-secondary hover:bg-opacity-20 transition-colors rounded-full">
                    <img
                      src={SecondIcon}
                      alt="Customer Care"
                      className="w-auto h-12"
                    />
                  </div>
                  <p className="font-medium italic uppercase">
                    {t("homepage.customerCare")}
                  </p>
                </div>
                <div className="text-center col-span-2 sm:col-span-1">
                  <div className="flex justify-center items-center w-24 h-24 mx-auto border-2 mb-4 border-white hover:bg-secondary hover:bg-opacity-20 transition-colors rounded-full">
                    <img
                      src={ThirdIcon}
                      alt="Excellence"
                      className="w-auto h-12"
                    />
                  </div>
                  <p className="font-medium italic uppercase">
                    {t("homepage.excellence")}
                  </p>
                </div>
              </div>
            </AnimatedText>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="w-full order-2 sm:order-2 lg:order-1 bg-primary text-white flex flex-col justify-center p-4 py-8 sm:p-8 sm:py-14">
            <AnimatedText
              className="text-left 2xl:max-w-3xl 2xl:mx-auto"
              animation="animate__fadeInLeft"
            >
              <h2 className="text-3xl lg:text-4xl font-tenor font-normal mb-2">
                {t("homepage.propertyManagementTitle")}
              </h2>
              <hr className="border-t border-white w-full my-4" />
              <p className="text-base leading-relaxed mb-4">
                {t("homepage.propertyManagmetSubtitle")}
              </p>
              <ul className="text-base leading-relaxed list-disc ml-5">
                {t("homepage.propertyManagementDescription", {
                  returnObjects: true,
                }).map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <Link to={"/kontakt"} className="mt-4">
                <button className="bg-transparent text-white border-white hover:bg-white hover:text-primary transition-colors border-2 px-6 py-2 mt-10">
                  {t("homepage.contactButton")}
                </button>
              </Link>
            </AnimatedText>
          </div>
          <div className="w-full order-1">
            <img
              src={Menaxhimi}
              alt="Menaxhimi"
              className="w-full h-full lg:h-[600px] 2xl:h-[700px] aspect-4/3 object-cover"
            />
          </div>
        </div>

        <Square />

        <div className="grid grid-cols-1 lg:grid-cols-2">
          <Link
            to={"/qera"}
            className="h-[400px] sm:h-[500px] lg:h-[550px] xl:h-[600px] bg-cover bg-center text-white relative group px-10"
            style={{ backgroundImage: `url(${RentImage})` }}
          >
            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-30 transition-opacity duration-300"></div>
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-center">
              <AnimatedText
                className="text-sm uppercase tracking-wide"
                animation="animate__fadeInUp"
              >
                {t("homepage.rentSectionTitle")}
              </AnimatedText>
              <AnimatedText
                className="text-3xl md:text-4xl font-bold py-6 mb-4 font-tenor"
                animation="animate__fadeInUp"
              >
                {t("homepage.rentSectionDescription")}
              </AnimatedText>
              <button className="px-6 py-2 border border-white text-white hover:bg-white hover:text-black transition">
                {t("homepage.rentSectionButton")}
              </button>
            </div>
          </Link>
          <Link
            to={"/shitje"}
            className="h-[400px] sm:h-[500px] lg:h-[550px] xl:h-[600px] bg-cover bg-center text-white relative group px-10"
            style={{ backgroundImage: `url(${SaleImage})` }}
          >
            <div className="absolute inset-0 bg-black opacity-50 group-hover:opacity-30 transition-opacity duration-300"></div>
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-center">
              <AnimatedText
                className="text-sm uppercase tracking-wide"
                animation="animate__fadeInUp"
              >
                {t("homepage.saleSectionTitle")}
              </AnimatedText>
              <AnimatedText
                className="text-3xl md:text-4xl font-bold py-6 mb-4 font-tenor"
                animation="animate__fadeInUp"
              >
                {t("homepage.saleSectionDescription")}
              </AnimatedText>
              <button className="px-6 py-2 border border-white text-white hover:bg-white hover:text-black transition">
                {t("homepage.saleSectionButton")}
              </button>
            </div>
          </Link>
        </div>
        <Impact />
      </div>
    </>
  );
};

export default HomePage;
