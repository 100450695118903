import {
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaLinkedinIn,
} from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="h-1 w-full bg-gradient-to-r from-[#A07B1F] to-[#E3C651] animate-move-gradient"></div>
      <div className="bg-primary text-white">
        <div className="container mx-auto flex justify-center items-center md:justify-between py-6">
          <p>
            {t("footer.copyright")} &copy; {t("footer.company")}
          </p>
          <div className="place-items-center space-x-4 hidden md:flex">
            <a
              href="https://www.facebook.com/britalbproperties/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-secondary transition-colors"
            >
              <FaFacebook size="1.4rem" />
            </a>
            <a
              href="https://www.instagram.com/britalbproperties/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-secondary transition-colors"
            >
              <FaInstagram size="1.4rem" />
            </a>
            <a
              href="https://linkedin.com/company/britalb-properties"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-secondary transition-colors"
            >
              <FaLinkedinIn size="1.4rem" />
            </a>
            <a
              href="https://www.tiktok.com/@britalb.propertie"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-secondary transition-colors"
            >
              <FaTiktok size="1.3rem" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
