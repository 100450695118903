import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import "animate.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "./store.js";
import reportWebVitals from "./reportWebVitals";
import HomePage from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import SalePage from "./pages/SalePage";
import RentPage from "./pages/RentPage.jsx";
import PropertiesPage from "./pages/PropertiesPage.jsx";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ErrorPage from "./pages/ErrorPage.jsx";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomePage />} />
      <Route path="/kontakt" element={<ContactUs />} />
      <Route path="/rreth-nesh" element={<AboutUs />} />
      <Route path="/qera" element={<RentPage />} />
      <Route path="/shitje" element={<SalePage />} />
      <Route path="/prona/:id" element={<PropertiesPage />} />
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <Provider store={store}>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          >
            <RouterProvider router={routes} />
          </GoogleReCaptchaProvider>
        </Provider>
      </HelmetProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
