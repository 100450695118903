import { Link } from "react-router-dom";
import { FaCircleCheck, FaPhoneVolume, FaEnvelope } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import Avatar from "../assets/images/avatar.webp";

const AgentCard = ({ agents, isAboutUsPage }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <>
      {agents.map((agent) => (
        <div
          key={agent.id}
          className={`flex flex-col items-center py-6 bg-white ${
            isAboutUsPage
              ? "shadow-xl rounded-md border-blue-100 border-2 px-4 hover:bg-blue-50"
              : ""
          }`}
        >
          <img
            className="w-28 h-28 rounded-full mb-2 object-cover border-4 border-gray-100"
            src={
              (agent.fields["Image"] &&
                agent.fields["Image"][0] &&
                agent.fields["Image"][0].url) ||
              Avatar
            }
            alt={agent.fields["Emri plote"]}
          />
          <h2 className="text-xl font-semibold mt-4">
            {agent.fields["Emri plote"] || "-"}
          </h2>
          <h3 className="flex items-center text-white bg-primary rounded-full mt-1 px-2 py-0.5">
            {currentLanguage === "en"
              ? agent.fields["EN Roli"] || "-"
              : agent.fields["AL Roli"] || "-"}
            <FaCircleCheck className="ml-2 text-green-500" />
          </h3>
          <p className="text-center text-gray-600 mt-2">
            {agent.fields["Pershkrimi"] || "-"}
          </p>
          <div className="flex items-center mt-4">
            <FaPhoneVolume />
            <Link to={`tel:${agent.fields["Numer telefoni"]}`}>
              <span className="ml-2 hover:text-primary">
                {agent.fields["Numer telefoni"] || "-"}
              </span>
            </Link>
          </div>
          <div className="flex items-center mt-2">
            <FaEnvelope />
            <Link to={`mailto:${agent.fields["Email"]}`}>
              <span className="ml-2 hover:text-primary">
                {agent.fields["Email"] || "-"}
              </span>
            </Link>
          </div>
        </div>
      ))}
    </>
  );
};

export default AgentCard;
