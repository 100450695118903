import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const apiUrl = process.env.REACT_APP_AIRTABLE_PROPERTIES_URL;
const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;

export const fetchData = createAsyncThunk("apiData/fetchData", async () => {
  let allRecords = [];
  let offset = null;

  do {
    const response = await fetch(`${apiUrl}?${offset ? `offset=${offset}` : ''}`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await response.json();
    allRecords = allRecords.concat(data.records);
    offset = data.offset;
  } while (offset);

  localStorage.setItem("apiData", JSON.stringify(allRecords));
  return allRecords;
});

const apiDataSlice = createSlice({
  name: "apiData",
  initialState: {
    data: JSON.parse(localStorage.getItem("apiData")) || null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default apiDataSlice.reducer;
