import VideoSample from "../assets/videos/video_sample.webm";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeVideo = () => {
  const { t } = useTranslation();

  return (
    <div className="relative h-screen z-0">
      <div className="absolute inset-0 z-20 flex items-center justify-center flex-col px-4">
        <h1 className="text-2xl sm:text-3xl md:text-4xl xl:text-5xl lg:max-w-3xl xl:max-w-5xl 2xl:max-w-6xl text-center font-semibold text-white uppercase animate__animated animate__fadeInDown">
          {t("homepage.homeVideo.safeChoice")}
          {t("homepage.homeVideo.profitableInvestments")}
        </h1>
        <p className="text-lg sm:text-xl md:text-2xl xl:text-3xl font-extralight text-white text-center mt-6 py-0 sm:py-2 md:py-6 lg:max-w-3xl xl:max-w-5xl animate__animated animate__fadeInUp">
          {t("homepage.homeVideo.personalizedSuggestions")}
        </p>
        <div className="mt-10 flex flex-col items-center space-y-4 xl:space-x-10 sm:flex-row sm:space-y-0 sm:space-x-6 md:text-lg xl:text-xl animate__animated animate__fadeInUp">
          <Link to={"/shitje"}>
            <button className="bg-transparent text-white border-white hover:bg-secondary hover:text-white transition-colors border-2 px-4 py-2 uppercase">
              {t("homepage.homeVideo.searchProperty")}
            </button>
          </Link>
          <Link to={"/kontakt"}>
            <button className="bg-transparent text-white border-white hover:bg-secondary hover:text-white border-2 px-4 py-2 uppercase">
              {t("homepage.homeVideo.listProperty")}
            </button>
          </Link>
        </div>
      </div>
      <div className="absolute inset-0 z-10 bg-black opacity-40"></div>
      <video
        autoPlay
        muted
        loop
        className="w-full h-full object-cover pointer-events-none"
      >
        <source src={VideoSample} type="video/webm" />
      </video>
    </div>
  );
};

export default HomeVideo;
