import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AgentCard from "../components/AgentCard";
import Gallery from "../components/Gallery";
import {
  FaBed,
  FaBath,
  FaCar,
  FaRulerCombined,
  FaMound,
  FaToolbox,
} from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import Meta from "../components/Meta";
import { Circles } from "react-loader-spinner";
import CoverImage from "../assets/images/cover.webp";

const PropertiesPage = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const currentLanguage = i18n.language;

  const {
    data: houses,
    status: houseStatus,
    error: houseError,
  } = useSelector((state) => state.apiData);
  const {
    data: agents,
    status: agentStatus,
    error: agentError,
  } = useSelector((state) => state.agentData);

  const house = houses ? houses.find((house) => house.id === id) : null;
  const houseAgents =
    house && agents && agents.records
      ? agents.records.filter((agent) =>
          house.fields["Agents"]?.includes(agent.id)
        )
      : [];

  function getStatusColor(statusColor) {
    switch (statusColor) {
      case "Në gjëndje":
        return "bg-green-500";
      case "Në diskutim":
        return "bg-rose-500";
      case "Shitur":
        return "bg-red-500";
      default:
        return "bg-primary";
    }
  }

  if (houseStatus === "loading" || agentStatus === "loading") {
    return (
      <div className="bg-primary h-screen flex flex-col items-center justify-center">
        <Circles color="#fff" />
        <span className="text-white text-lg mt-4">
          {t("propertiesPage.loading")}
        </span>
      </div>
    );
  }

  if (houseStatus === "failed" || agentStatus === "failed") {
    return (
      <div className="bg-primary h-screen flex flex-col items-center justify-center">
        <span className="text-white text-lg mt-4">
          {t("propertiesPage.error")}: {houseError} {agentError}
        </span>
      </div>
    );
  }

  if (!house) {
    return (
      <div className="flex flex-col items-center justify-center w-full bg-primary h-screen">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-white">404</h1>
          <p className="text-lg pb-6 pt-4 text-white">
            {t("errorPage.houseNotFound")}
          </p>
          <Link
            to="/"
            className="bg-secondary text-lg rounded-md text-primary font-bold hover:bg-deepSecondary transition-colors px-4 py-2"
          >
            {t("errorPage.goHome")}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <Meta
        title={house.fields["Title"] || t("propertiesPage.noTitle")}
        subtitle={t("homepage.meta.subtitle")}
        description={house.fields["AL Description"]}
        keyword={t("homepage.meta.keyword")}
        image={CoverImage}
      />
      <div className="bg-primary">
        <div className="pt-24 py-10 px-2 container mx-auto">
          <Gallery images={house.fields["Pictures"]} />
          <div className="flex justify-between items-center gap-2 bg-white my-4 rounded-md p-6">
            {/* Title and Location */}
            <div className="w-1/2 space-y-1">
              <div className="flex flex-col">
                <h1 className="text-2xl font-bold text-gray-900">
                  {house.fields["Title"] || t("propertiesPage.noTitle")}
                </h1>
                <span>{house.fields["Type"]}</span>
              </div>
              <p className="text-gray-500">
                {house.fields["Address"] || t("propertiesPage.noAddress")},{" "}
                {house.fields["City"]}
              </p>
            </div>
            {/* Price Information */}
            <div className="w-1/2 space-y-1">
              <div className="text-right flex flex-col items-end">
                <p
                  className={`${getStatusColor(
                    house.fields["AL Status"]
                  )} py-0.5 w-28 sm:w-fit px-2 text-white rounded-md`}
                >
                  {currentLanguage === "en"
                    ? house.fields["EN Status"] || t("propertiesPage.noStatus")
                    : house.fields["AL Status"] || t("propertiesPage.noStatus")}
                </p>
                <p className="text-primary text-xl font-semibold">
                  {house.fields["Updated price"]
                    ? `${Number(
                        house.fields["Updated price"]
                      ).toLocaleString()} €`
                    : house.fields["Starter price"]
                    ? `${Number(
                        house.fields["Starter price"]
                      ).toLocaleString()} €`
                    : "- €"}
                </p>
              </div>
            </div>
          </div>

          <div className="grid lg:flex lg:space-x-4 w-full mt-4">
            <div className="w-full lg:w-3/4 p-6 bg-white rounded-md">
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                <div className="flex items-center gap-3">
                  <div className="aspect-w-1 aspect-h-1 bg-secondary hover:bg-deepSecondary border border-black rounded-md p-2 flex justify-center items-center">
                    <FaBed className="text-3xl text-black" />
                  </div>
                  <div>
                    <div className="text-sm font-bold">
                      {t("propertiesPage.bedrooms")}
                    </div>
                    <div className="text-base">
                      {house.fields["Bedrooms"] || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="aspect-w-1 aspect-h-1 bg-secondary hover:bg-deepSecondary border border-black rounded-md p-2 flex justify-center items-center">
                    <FaBath className="text-3xl text-black" />
                  </div>
                  <div>
                    <div className="text-sm font-bold">
                      {t("propertiesPage.bathrooms")}
                    </div>
                    <div className="text-base">
                      {house.fields["Baths"] || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="aspect-w-1 aspect-h-1 bg-secondary hover:bg-deepSecondary border border-black rounded-md p-2 flex justify-center items-center">
                    <FaRulerCombined className="text-3xl text-black" />
                  </div>
                  <div>
                    <div className="text-sm font-bold">
                      {t("propertiesPage.space")}
                    </div>
                    <div className="text-base">
                      {house.fields["Building m2"] || "N/A"} m<sup>2</sup>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="aspect-w-1 aspect-h-1 bg-secondary hover:bg-deepSecondary border border-black rounded-md p-2 flex justify-center items-center">
                    <FaMound className="text-3xl text-black" />
                  </div>
                  <div>
                    <div className="text-sm font-bold">
                      {t("propertiesPage.land")}
                    </div>
                    <div className="text-base">
                      {house.fields["Land m2"] || "N/A"} m<sup>2</sup>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="aspect-w-1 aspect-h-1 bg-secondary hover:bg-deepSecondary border border-black rounded-md p-2 flex justify-center items-center">
                    <FaCar className="text-3xl text-black" />
                  </div>
                  <div>
                    <div className="text-sm font-bold">
                      {t("propertiesPage.parking")}
                    </div>
                    <div className="text-base">
                      {house.fields["Parking"] || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="aspect-w-1 aspect-h-1 bg-secondary hover:bg-deepSecondary border border-black rounded-md p-2 flex justify-center items-center">
                    <FaToolbox className="text-3xl text-black" />
                  </div>
                  <div>
                    <div className="text-sm font-bold">
                      {t("propertiesPage.builtIn")}
                    </div>
                    <div className="text-base">
                      {house.fields["Build in"] || "N/A"}
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-lg py-8">
                {house.fields["AL Description"] ||
                  t("propertiesPage.noDescription")}
              </p>

              <h2 className="text-2xl font-bold mb-2">
                {t("propertiesPage.other")}
              </h2>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <strong>{t("propertiesPage.cooling")}:</strong>{" "}
                  {house.fields["Cooling"] || "N/A"}
                </div>
                <div>
                  <strong>{t("propertiesPage.heating")}:</strong>{" "}
                  {house.fields["Heating"] || "N/A"}
                </div>
                <div>
                  <strong>{t("propertiesPage.remodels")}:</strong>{" "}
                  {house.fields["Last remodel"] || "N/A"}
                </div>
                <div>
                  <strong>{t("propertiesPage.status")}:</strong>{" "}
                  {house.fields["AL Status"] || "N/A"}
                </div>
                <div>
                  <strong>{t("propertiesPage.city")}:</strong>{" "}
                  {house.fields["City"] || "N/A"}
                </div>
                <div>
                  <strong>{t("propertiesPage.address")}:</strong>{" "}
                  {house.fields["Address"] || "N/A"}
                </div>
                {house.fields["Updated price"] && (
                  <div>
                    <strong>{t("propertiesPage.startingPrice")}:</strong>
                    {Number(house.fields["Starter price"]).toLocaleString()} €
                  </div>
                )}
              </div>
            </div>
            <div className="w-full lg:w-1/4 p-6 bg-white rounded-md mt-4 lg:mt-0 h-fit">
              {houseAgents.length > 0 ? (
                <AgentCard agents={houseAgents} isAboutUsPage={false} />
              ) : (
                <div className="text-center">{t("propertiesPage.noAgent")}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesPage;
