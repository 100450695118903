const HeaderImage = ({ image, title, actualPage }) => {
  return (
    <div className="relative w-full h-[250px] sm:h-[350px] md:h-[350px] lg:h-[350px]">
      <div className="bg-black bg-opacity-50 absolute inset-0"></div>
      <img
        src={image}
        alt="HeaderImage"
        className="w-full h-full object-cover object-center"
      />
      <div className="absolute inset-0 flex items-center justify-center flex-col space-y-4 pt-6">
        <h1 className="text-3xl lg:text-4xl text-center font-bold text-white animate__animated animate__fadeInUp">
          {title}
        </h1>
        <p className="mb-2 text-white text-center font-bold animate__animated animate__fadeInUp">
          {actualPage}
        </p>
      </div>
      <div className="h-1 w-full bg-gradient-to-r from-[#A07B1F] to-[#E3C651] animate-move-gradient"></div>
    </div>
  );
};

export default HeaderImage;
