import Building from "../assets/images/building-wallpaper.webp";
import useIntersectionObserver from "../utils/useIntersectionObserver";
import { useTranslation } from "react-i18next";

const stats = [
  {
    value: "homepage.stats.0.value",
    description: "homepage.stats.0.description",
  },
  {
    value: "homepage.stats.1.value",
    description: "homepage.stats.1.description",
  },
  {
    value: "homepage.stats.2.value",
    description: "homepage.stats.2.description",
  },
  {
    value: "homepage.stats.3.value",
    description: "homepage.stats.3.description",
  },
];

const AnimatedText = ({ children, className, animation }) => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <div
      ref={ref}
      className={`${className} ${
        isVisible ? `animate__animated ${animation}` : ""
      }`}
    >
      {children}
    </div>
  );
};

const Square = () => {
  const { t } = useTranslation();

  return (
    <div
      className="relative bg-cover bg-center"
      style={{ backgroundImage: `url(${Building})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="relative container mx-auto z-10 px-4 py-24 text-center">
        <AnimatedText
          className="text-white text-3xl font-bold max-w-xl mx-auto"
          animation="animate__fadeInUp"
        >
          {t("homepage.squareHeader.title")}
        </AnimatedText>
        <hr className="border-t border-white w-3/6 mx-auto my-8" />
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 justify-items-center sm:px-4 md:px-16 lg:px-32 lg:mt-14 xl:px-0">
          {stats.map((stat, index) => (
            <AnimatedText
              key={index}
              className="bg-transparent hover:bg-white hover:bg-opacity-30 transition-colors border border-white w-72 h-40 sm:h-60 p-6 flex items-center flex-col justify-center"
              animation="animate__fadeInUp"
            >
              <h3 className="text-white text-3xl font-bold mb-2">
                {t(stat.value)}
              </h3>
              <p className="text-white">{t(stat.description)}</p>
            </AnimatedText>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Square;
