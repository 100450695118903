import { Link } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  FaBed,
  FaBath,
  FaMapLocationDot,
  FaRulerCombined,
  FaCircleInfo,
  FaHouse,
} from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import SaleHeaderImage from "../assets/images/sale-header.webp";
import NoImage from "../assets/images/no-image.webp";
import Meta from "../components/Meta";
import HeaderImage from "../components/HeaderImage";
import CoverImage from "../assets/images/cover.webp";

const RentPage = () => {
  const { t, i18n } = useTranslation();
  const { data, status, error } = useSelector((state) => state.apiData);
  const currentLanguage = i18n.language;

  const initialFilters = {
    minPrice: 0,
    maxPrice: null,
    bedrooms: 0,
    bathrooms: 0,
    location: "",
    meters: 0,
  };

  const [filters, setFilters] = useState(initialFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const housesPerPage = 21;

  const sortedData = data
    ? data
        .slice()
        .sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
    : [];

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if ((name === "bedrooms" || name === "bathrooms") && value === "0") {
      setFilters({ ...filters, [name]: 0 });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const filteredHouses = sortedData
    ? sortedData.filter((house) => {
        const housePrice = Number(house.fields["Starter price"]) || 0;
        const purpose = house.fields.Purpose || "";
        const city = house.fields.City || "";
        const address = house.fields.Address || "";

        return (
          purpose.includes("Qera") &&
          housePrice >= filters.minPrice &&
          (filters.maxPrice === null || housePrice <= filters.maxPrice) &&
          (filters.bedrooms === 0 ||
            house.fields.Bedrooms === Number(filters.bedrooms)) &&
          (filters.bathrooms === 0 ||
            house.fields.Baths === Number(filters.bathrooms)) &&
          (filters.location === "" ||
            city.toLowerCase().startsWith(filters.location.toLowerCase()) ||
            address.toLowerCase().startsWith(filters.location.toLowerCase())) &&
          (filters.meters === 0 ||
            house.fields["Land m2"] >= Number(filters.meters))
        );
      })
    : [];

  // Pagination logic
  const indexOfLastHouse = currentPage * housesPerPage;
  const indexOfFirstHouse = indexOfLastHouse - housesPerPage;
  const currentHouses = filteredHouses.slice(
    indexOfFirstHouse,
    indexOfLastHouse
  );

  const totalPages = Math.ceil(filteredHouses.length / housesPerPage);

  return (
    <>
      <Meta
        title={t("rentPage.meta.title")}
        subtitle={t("rentPage.meta.subtitle")}
        description={t("rentPage.meta.description")}
        keyword={t("rentPage.meta.keyword")}
        image={CoverImage}
      />

      <HeaderImage
        image={SaleHeaderImage}
        title={t("rentPage.header.title")}
        actualPage={t("rentPage.header.subtitle")}
      />

      <div className="flex flex-col items-center justify-center w-full bg-primary">
        <div className="bg-primary container py-6 px-2 mx-auto w-full flex flex-col">
          <div className="w-full bg-white rounded-md mb-4 p-4 text-center">
            <p className="text-gray-800">{t("rentPage.filters.description")}</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:flex gap-4">
            {/* Filter Section */}
            <div className="w-full md:w-1/3 bg-white h-fit rounded-md p-4 lg:sticky-filter">
              <h2 className="text-xl font-bold mb-4">
                {t("rentPage.filters.title")}
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-gray-700">
                    {t("rentPage.filters.minPrice")}
                  </label>
                  <input
                    type="number"
                    name="minPrice"
                    value={filters.minPrice}
                    onChange={handleFilterChange}
                    className="w-full mt-1 p-2 border border-gray-300"
                    placeholder={t("rentPage.filters.minPrice")}
                  />
                </div>
                <div>
                  <label className="block text-gray-700">
                    {t("rentPage.filters.maxPrice")}
                  </label>
                  <input
                    type="number"
                    name="maxPrice"
                    value={filters.maxPrice || ""}
                    onChange={handleFilterChange}
                    className="w-full mt-1 p-2 border border-gray-300"
                    placeholder={t("rentPage.filters.maxPrice")}
                  />
                </div>

                <div>
                  <label className="block text-gray-700">
                    {t("rentPage.filters.bedrooms")}
                  </label>
                  <select
                    name="bedrooms"
                    value={filters.bedrooms}
                    onChange={handleFilterChange}
                    className="w-full mt-1 p-2 border border-gray-300"
                  >
                    <option value="0">{t("rentPage.filters.all")}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div>
                  <label className="block text-gray-700">
                    {t("rentPage.filters.bathrooms")}
                  </label>
                  <select
                    name="bathrooms"
                    value={filters.bathrooms}
                    onChange={handleFilterChange}
                    className="w-full mt-1 p-2 border border-gray-300"
                  >
                    <option value="0">{t("rentPage.filters.all")}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div>
                  <label className="block text-gray-700">
                    {t("rentPage.filters.location")}
                  </label>
                  <input
                    type="text"
                    name="location"
                    value={filters.location}
                    onChange={handleFilterChange}
                    className="w-full mt-1 p-2 border border-gray-300"
                    placeholder={t("rentPage.filters.locationPlaceholder")}
                  />
                </div>
                <div>
                  <label className="block text-gray-700">
                    {t("rentPage.filters.meters")}
                  </label>
                  <input
                    type="number"
                    name="meters"
                    value={filters.meters}
                    onChange={handleFilterChange}
                    className="w-full mt-1 p-2 border border-gray-300"
                    placeholder={t("rentPage.filters.metersPlaceholder")}
                  />
                </div>
                <button
                  onClick={() => setFilters(initialFilters)}
                  className="w-full bg-primary text-white p-2 hover:bg-secondary mt-4"
                >
                  {t("rentPage.filters.clearFilters")}
                </button>
              </div>
            </div>

            {/* Houses Grid Section */}
            <div className="w-full">
              {status === "loading" ? (
                <div className="text-left p-4 bg-white rounded-md">
                  <p>{t("rentPage.loading")}</p>
                </div>
              ) : status === "failed" ? (
                <div className="text-left p-4 bg-white rounded-md">
                  <p>
                    {t("rentPage.error")}: {error}
                  </p>
                </div>
              ) : currentHouses.length === 0 ? (
                <div className="text-left p-4 bg-white rounded-md">
                  <p>{t("rentPage.noHouses")}</p>
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                  {currentHouses.map((house) => (
                    <Link
                      to={`/prona/${house.id}`}
                      key={house.id}
                      className="bg-white shadow-xl rounded-md"
                    >
                      <img
                        src={house.fields["Pictures"]?.[0]?.url || NoImage}
                        alt={house.fields["Title"]}
                        className="w-full h-40 object-cover rounded-t-md"
                      />
                      <div className="p-4">
                        <div className="grid grid-cols-2 gap-2 mb-4">
                          <h3 className="text-lg font-bold">
                            {house.fields["Title"] ||
                              t("propertiesPage.noTitle")}
                          </h3>
                          <p className="text-white bg-secondary w-fit rounded-md px-2 py-0.5 justify-self-end">
                            {house.fields["Updated price"]
                              ? `${Number(
                                  house.fields["Updated price"]
                                ).toLocaleString()} €`
                              : house.fields["Starter price"]
                              ? `${Number(
                                  house.fields["Starter price"]
                                ).toLocaleString()} €`
                              : "- €"}
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-2 text-gray-700 text-sm">
                          <div className="flex items-center">
                            <FaHouse className="mr-1" />{" "}
                            {house.fields["Type"] || "-"}
                          </div>
                          <div className="flex items-center">
                            <FaCircleInfo className="mr-1" />{" "}
                            {currentLanguage === "en"
                              ? house.fields["EN Status"] ||
                                t("propertiesPage.noStatus")
                              : house.fields["AL Status"] ||
                                t("propertiesPage.noStatus")}
                          </div>
                          <div className="flex items-center">
                            <FaBed className="mr-1" />{" "}
                            {house.fields["Bedrooms"] || "-"}{" "}
                            {t("rentPage.bedrooms")}
                          </div>
                          <div className="flex items-center">
                            <FaBath className="mr-1" />{" "}
                            {house.fields["Baths"] || "-"}{" "}
                            {t("rentPage.bathrooms")}
                          </div>
                          <div className="flex items-center">
                            <FaMapLocationDot className="mr-1" />{" "}
                            {house.fields["City"] || "-"}
                          </div>
                          <div className="flex items-center">
                            <FaRulerCombined className="mr-1" />{" "}
                            {house.fields["Land m2"] || "-"} m<sup>2</sup>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              )}

              {/* Pagination Controls */}
              <div className="grid grid-cols-6 sm:grid-cols-10 lg:flex lg:justify-end gap-2 mt-8">
                {[...Array(totalPages)].map((_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => setCurrentPage(index + 1)}
                    className={`px-4 py-2 ${
                      currentPage === index + 1
                        ? "bg-transparent border-white border-2 text-white"
                        : "bg-gray-200"
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RentPage;
