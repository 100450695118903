import { useState } from "react";
import { useTranslation } from "react-i18next";
import ContactHeaderImage from "../assets/images/contact-header.webp";
import {
  FaPhoneVolume,
  FaEnvelope,
  FaMapLocationDot,
  FaInstagram,
  FaFacebook,
  FaTiktok,
  FaLinkedinIn,
} from "react-icons/fa6";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Meta from "../components/Meta";
import HeaderImage from "../components/HeaderImage";
import CoverImage from "../assets/images/cover.webp";

const ContactUs = () => {
  const { t } = useTranslation();
  const [statusMessage, setStatusMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("contact_form");

    try {
      const response = await fetch(process.env.REACT_APP_API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...formData, token }),
      });

      const result = await response.json();
      if (result.success) {
        setIsSuccess(true);
        setStatusMessage(t("contactUs.contact.form.statusMessage.success"));
      } else {
        setIsSuccess(false);
        setStatusMessage(
          result.message || t("contactUs.contact.form.statusMessage.error")
        );
      }
    } catch (error) {
      setIsSuccess(false);
      setStatusMessage(t("contactUs.contact.form.statusMessage.genericError"));
    }
  };

  return (
    <>
      <Meta
        title={t("contactUs.meta.title")}
        subtitle={t("contactUs.meta.subtitle")}
        description={t("contactUs.meta.description")}
        keyword={t("contactUs.meta.keyword")}
        image={CoverImage}
      />

      <HeaderImage
        image={ContactHeaderImage}
        title={t("contactUs.header.title")}
        actualPage={t("contactUs.header.subtitle")}
      />

      <div className="flex-grow flex items-center justify-center w-full">
        <div className="bg-white py-8 mx-auto max-w-7xl w-full grid grid-cols-1 lg:grid-cols-2 lg:py-16 px-6 gap-8">
          <div className="flex flex-col items-center sm:items-center md:items-center lg:items-start">
            <h2 className="text-2xl font-bold mb-6 text-center md:text-left animate__animated animate__fadeInLeft">
              {t("contactUs.contact.sectionTitle")}
            </h2>
            <div className="flex flex-col items-center sm:items-center md:items-center lg:items-start space-y-8">
              <div className="flex items-center animate__animated animate__fadeInLeft">
                <a
                  href="tel:+355694286063"
                  className="flex items-center text-gray-700 hover:text-primary"
                >
                  <FaPhoneVolume className="mr-2" />
                  +355 69 428 6063
                </a>
              </div>
              <div className="flex items-center animate__animated animate__fadeInLeft">
                <a
                  href="mailto:info@britalb.com"
                  className="flex items-center text-gray-700 hover:text-primary"
                >
                  <FaEnvelope className="mr-2" />
                  info@britalb.com
                </a>
              </div>
              <div className="flex items-center animate__animated animate__fadeInLeft">
                <a
                  href="https://maps.google.com/maps/dir//Britalb+Properties+Tirana+1016/@41.3295584,19.8259663,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x135031993f14b455:0x44628e24222a0994"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-gray-700 hover:text-primary"
                >
                  <FaMapLocationDot className="text-gray-700 mr-2" />
                  {t("contactUs.contact.address")}
                </a>
              </div>
              <div className="flex items-center space-x-6 mt-8 animate__animated animate__fadeInUp">
                <a
                  href="https://www.facebook.com/britalbproperties/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-700 hover:text-secondary"
                >
                  <FaFacebook size="1.8rem" />
                </a>
                <a
                  href="https://www.instagram.com/britalbproperties/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-700 hover:text-secondary"
                >
                  <FaInstagram size="2.0rem" />
                </a>
                <a
                  href="https://linkedin.com/company/britalb-properties"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-700 hover:text-secondary"
                >
                  <FaLinkedinIn size="1.8rem" />
                </a>
                <a
                  href="https://www.tiktok.com/@britalb.propertie"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-700 hover:text-secondary"
                >
                  <FaTiktok size="1.6rem" />
                </a>
              </div>
            </div>
          </div>
          <div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-gray-700">
                  {t("contactUs.contact.form.name")}
                </label>
                <input
                  type="text"
                  name="name"
                  className="w-full mt-1 p-2 border border-gray-300"
                  placeholder={t("contactUs.contact.form.namePlaceholder")}
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  {t("contactUs.contact.form.email")}
                </label>
                <input
                  type="email"
                  name="email"
                  className="w-full mt-1 p-2 border border-gray-300"
                  placeholder={t("contactUs.contact.form.emailPlaceholder")}
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  {t("contactUs.contact.form.phone")}
                </label>
                <input
                  type="text"
                  name="phone"
                  className="w-full mt-1 p-2 border border-gray-300"
                  placeholder={t("contactUs.contact.form.phonePlaceholder")}
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  {t("contactUs.contact.form.message")}
                </label>
                <textarea
                  name="message"
                  className="w-full mt-1 p-2 border border-gray-300 "
                  rows="4"
                  placeholder={t("contactUs.contact.form.messagePlaceholder")}
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-primary text-white p-2 hover:bg-secondary hover:text-black transition-colors"
              >
                {t("contactUs.contact.form.submitButton")}
              </button>
            </form>
            {statusMessage && (
              <div
                className={`mt-4 p-2 text-center text-white ${
                  isSuccess ? "bg-green-600" : "bg-red-600"
                }`}
              >
                {statusMessage}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-full">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.0483305413036!2d19.823391376674046!3d41.32956239957906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135031993f14b455%3A0x44628e24222a0994!2sBritalb%20Properties!5e0!3m2!1sen!2s!4v1720882029637!5m2!1sen!2s"
          width="100%"
          height="450"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
          className="border-0"
        ></iframe>
      </div>
    </>
  );
};

export default ContactUs;
