import useIntersectionObserver from "../utils/useIntersectionObserver";
import { useTranslation } from "react-i18next";

const impact = [
  {
    text: "homepage.impact.0.text",
    name: "homepage.impact.0.name",
  },
  {
    text: "homepage.impact.1.text",
    name: "homepage.impact.1.name",
  },
  {
    text: "homepage.impact.2.text",
    name: "homepage.impact.2.name",
  },
];

const AnimatedText = ({ children, className, animation }) => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <div
      ref={ref}
      className={`${className} ${
        isVisible ? `animate__animated ${animation}` : ""
      }`}
    >
      {children}
    </div>
  );
};

const Impact = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-primary py-14 flex items-center justify-center flex-col px-4">
      <div className="container">
        <AnimatedText
          className="text-secondary text-2xl lg:text-3xl text-center px-4 font-bold mb-10"
          animation="animate__fadeInUp"
        >
          {t("homepage.impact.header")}
        </AnimatedText>
        <hr className="w-full mx-auto px-6 my-4" />
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {impact.map((testimonial, index) => (
            <AnimatedText
              key={index}
              className="bg-transparent p-6 flex flex-col justify-between rounded-xl hover:bg-white hover:bg-opacity-10 hover:rounded-xl transition-colors"
              animation="animate__fadeInUp"
            >
              <p className="text-white mb-4 text-justify">
                "{t(testimonial.text)}"
              </p>
              <p className="font-bold bg-gray-200 text-primary py-3 px-6">
                {t(testimonial.name)}
              </p>
            </AnimatedText>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Impact;
