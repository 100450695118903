import { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchData } from "../slices/apiDataSlice";
import { fetchAgentData } from "../slices/agentDataSlice";
import { FaArrowsRotate } from "react-icons/fa6";

const RefreshButton = () => {
  const dispatch = useDispatch();
  const [isRotating, setIsRotating] = useState(false);

  const fetchAndStoreTimestamp = useCallback(async () => {
    try {
      setIsRotating(true);
      await dispatch(fetchData()).unwrap();
      await dispatch(fetchAgentData()).unwrap();
      const now = Date.now();
      localStorage.setItem("lastFetchTime", now.toString());
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsRotating(false);
    }
  }, [dispatch]);

  useEffect(() => {
    const shouldFetchData = () => {
      const lastFetchTime = localStorage.getItem("lastFetchTime");
      const now = Date.now();
      return !lastFetchTime || now - parseInt(lastFetchTime, 10) > 1800000;
    };

    if (shouldFetchData()) {
      fetchAndStoreTimestamp();
    }

    const interval = setInterval(() => {
      if (shouldFetchData()) {
        fetchAndStoreTimestamp();
      }
    }, 1800000);

    return () => clearInterval(interval);
  }, [fetchAndStoreTimestamp]);

  return (
    <button
      className="fixed bottom-24 right-3 p-3 bg-secondary text-white rounded-full shadow-lg hover:bg-deepSecondary transition-colors"
      onClick={fetchAndStoreTimestamp}
    >
      <FaArrowsRotate
        className={`h-6 w-6 ${isRotating ? "animate-spin" : ""}`}
      />
    </button>
  );
};

export default RefreshButton;
