import { Helmet } from "react-helmet-async";

const Meta = ({ title, subtitle, description, keyword, image }) => {
  return (
    <Helmet>
      <title>{subtitle ? `${title} - ${subtitle}` : title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keyword} />
      <meta
        property="og:title"
        content={subtitle ? `${title} - ${subtitle}` : title}
      />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

export default Meta;
