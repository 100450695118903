import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const apiUrl = process.env.REACT_APP_AIRTABLE_AGENT_URL;
const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;

export const fetchAgentData = createAsyncThunk(
  "agentData/fetchAgentData",
  async () => {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await response.json();
    localStorage.setItem("agentData", JSON.stringify(data));
    return data;
  }
);

const agentDataSlice = createSlice({
  name: "agentData",
  initialState: {
    data: JSON.parse(localStorage.getItem("agentData")) || null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAgentData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchAgentData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default agentDataSlice.reducer;
