import { configureStore } from "@reduxjs/toolkit";
import apiDataReducer from "./slices/apiDataSlice";
import agentDataReducer from "./slices/agentDataSlice";

const store = configureStore({
  reducer: {
    apiData: apiDataReducer,
    agentData: agentDataReducer,
  },
});

export default store;
