import Header from "./components/Header";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";
import RefreshButton from "./components/RefreshButton";
import { useScrollToTop } from "./components/ScrollToTop.jsx";

const App = () => {
  useScrollToTop();
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <RefreshButton />
    </>
  );
};

export default App;
